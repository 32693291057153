import {
    CardRevealComplete,
    Complete,
    Setup,
    StepBoxReveal,
    StepCardsReveal,
    StepIdle,
    StepPackReveal,
    UpdateCard,
    UpdateStep,
} from '@fanatics/resn-api/instant-rips';
import { defineComponent } from 'vue';

import { gsap } from '@resn/gsap';

import { useFanatics } from './FanaticsProvider';
import { useWindowMessage } from '~/composables/useWindowMessage';
import { BOX_DUR, CARDS_DUR, COMPLETE_DUR, PACK_DUR } from '~/core/constants';

const StateManager = defineComponent({
    setup() {
        const fanatics = useFanatics();

        useWindowMessage({
            onReceiveMessage: (event) => {
                if (!event.data.type) {
                    console.warn(
                        'StateManager: Window message object does not have a type:',
                        event
                    );
                    return;
                }

                // console.log('StateManager: Message from parent:', event.data);

                const { data } = event.data;

                switch (event.data.type) {
                    case Setup:
                        var shouldReset = fanatics.step.value !== StepIdle;

                        if (shouldReset) {
                            resetAndSetup(data);
                            return;
                        }

                        fanatics.setup(data);

                        if (data.rerip) startReripFlow();

                        break;
                    case UpdateStep:
                        fanatics.updateStep(data);
                        break;
                    case UpdateCard:
                        fanatics.updateCard(data);
                        break;
                    case Complete:
                        fanatics.complete();

                        gsap.delayedCall(COMPLETE_DUR, () => {
                            fanatics.updateStep(StepIdle);
                            fanatics.colorPaletteIndex.value = 0;
                        });
                        break;
                }
            },
        });

        let tlRerip;

        const resetAndSetup = (data) => {
            tlRerip?.kill();

            fanatics.updateStep('');
            fanatics.complete();
            console.log('StateManager: Reset');

            gsap.delayedCall(COMPLETE_DUR, () => {
                console.log('StateManager: Reset to StepIdle and call Setup');

                fanatics.updateStep(StepIdle);
                fanatics.colorPaletteIndex.value = 0;

                requestAnimationFrame(() => {
                    fanatics.setup(data);
                });
            });
        };

        const startReripFlow = () => {
            const cards = fanatics.cards.value;

            let cardIndex = 0;

            const updateCard = () => {
                const currentCard = cards[cardIndex];

                if (currentCard) {
                    fanatics.updateCard({ index: cardIndex, active: true, revealed: false });
                    console.log('StateManager: Card show:', cardIndex);

                    gsap.delayedCall(1, () => {
                        fanatics.updateCard({ index: cardIndex, revealed: true });
                        console.log('StateManager: Card reveal:', cardIndex);

                        fanatics.events.once(CardRevealComplete, () => {
                            if (cardIndex < cards.length - 1) {
                                cardIndex++;
                                updateCard();
                            }
                        });
                    });
                }
            };

            const tl = gsap.timeline();

            tl.add(() => fanatics.updateStep(StepIdle));
            tl.add(() => fanatics.updateStep(StepBoxReveal), `+=${BOX_DUR + 0.3}`);
            tl.add(() => fanatics.updateStep(StepPackReveal), `+=${PACK_DUR + 0.5}`);
            tl.add(() => fanatics.updateStep(StepCardsReveal), `+=${CARDS_DUR}`);

            tl.addLabel('cardReveal', '+=1.5');
            tl.add(updateCard, '+=1.5');

            tlRerip = tl;
        };
    },

    render() {
        if (this.$slots.default) {
            return this.$slots.default();
        }

        return null;
    },
});

export default StateManager;
